<template>
  <CompanyWrapper>
    <div class="text-left">
      <ul>
        <li v-for="(paragraph, index) in paragraphs" :key="paragraph">
          {{ paragraph }}
          <span v-if="index == paragraphs.length - 1"><a :href="terms" target="_blank">termos de uso</a>&nbsp;
            <a :href="policy" target="_blank">politicas de privacidade</a></span>
        </li>
      </ul>
    </div>
  </CompanyWrapper>
  <FooterWrapper>
    <div class="border-t border-gray-300">
      <div class="lg:flex lg:flex-col lg:items-center lg:justify-center">
        <div class="my-5">
          <input type="checkbox" id="accept" name="accept" v-model="accept" />
          Li e concordo com o termo acima
        </div>
        <ButtonComponent class="w-96" :accept="accept"
          :to="`/empresas/${$route.params.id}/${$route.params.type}/formulario`" title="continua" />
      </div>
    </div>
  </FooterWrapper>
</template>

<script>
import { companyStorage } from "@/storage/company";
import CompanyWrapper from "@/components/CompanyWrapper.vue";
import FooterWrapper from "@/components/FooterWrapper.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
  name: "TermsView",
  data() {
    return {
      companyStorage,
      accept: false,
      paragraphs: null,
      terms: null,
      policy: null,
    };
  },
  components: {
    CompanyWrapper,
    FooterWrapper,
    ButtonComponent,
  },
  async created() {
    try {
      const { id, type } = this.$route.params;
      const company_id = id;
      const content_type = {
        denuncia: "complaint",
        solicitacao: "request",
        sugestao: "suggestion",
      }[type];

      const promises = [
        this.axios.get(
          `/public/companies/${company_id}/privacy-policy/${content_type}/aws-url/get`
        ),
        this.axios.get(
          `/public/companies/${company_id}/terms-of-use/${content_type}/aws-url/get`
        ),
      ];

      const response = await Promise.all(promises);

      this.policy = response[0]?.data?.url;
      this.terms = response[1]?.data?.url;
    } catch (error) {
      // TODO adicionar o toast aqui
      console.log(error);
    }
  },
  watch: {
    "companyStorage.data"(data) {
      this.paragraphs =
        data[
          `${{
            denuncia: "complaint",
            solicitacao: "request",
            sugestao: "suggestion",
          }[this.$route.params.type]
          }Fields`
        ].terms.split("\n\n");
    },
  },
};
</script>

<style scoped>
ul {
  list-style-position: outside;
}

li {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

ul li::before {
  content: "\2022";
  color: #5095fd;
  font-weight: bold;
  display: inline-block;
  list-style-position: outside;
}

a:link {
  color: #5095fd;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #bdd5ff;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #5095fd;
  background-color: transparent;
  text-decoration: underline;
}
</style>
