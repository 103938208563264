<template>
  <LoadingBig v-if="loading" class="w-screen h-screen absolute" />

  <div id="main" v-else class="bg-white border-l-2 border-r-2 border-gray-200 px-5 pb-10">
    <CompanyLabel> {{ company?.data?.name }} </CompanyLabel>
    <slot></slot>
  </div>
</template>

<script>
import CompanyLabel from "@/components/CompanyLabel.vue";
import { companyStorage } from "@/storage/company.js";
import LoadingBig from "@/components/LoadingBig.vue";

export default {
  async created() {
    try {
      if (this.$route?.params?.id !== undefined) {
        companyStorage.setId(this.$route?.params?.id);
        const response = await this.axios
          .get(`/public/companies/${this.$route.params.id}`)

        companyStorage.setData(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  components: {
    CompanyLabel,
    LoadingBig,
  },
  data() {
    return {
      company: companyStorage,
      loading: true,
    };
  },
};
</script>
